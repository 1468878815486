import request from "./request";

// 考勤数据列表
export function totalData(data) {
	return request({
		url: "/workinglog/totalData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 个人考勤数据
export function personageWorking(data) {
	return request({
		url: "/workinglog/byAdminIdGetAllWorkLog",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 每日打卡
export function getEveryDayData(data) {
	return request({
		url: "/workinglog/everyDayDate",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 交接获取详情
export function getConnectInfo(data) {
	return request({
		url: "/connect/getInfo",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 交接列表
export function getConnectList(data) {
	return request({
		url: "/connect/pageList",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 交接添加
export function addConnectData(data) {
	return request({
		url: "/connect/addData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 交接的编辑
export function editConnectData(data) {
	return request({
		url: "/connect/editData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 资产获取详情
export function getPropertyInfo(data) {
	return request({
		url: "/property/getInfo",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 资产数据列表
export function getPropertyList(data) {
	return request({
		url: "/property/getListData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 资产添加
export function addPropertyData(data) {
	return request({
		url: "/property/addData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 资产编辑
export function editPropertyData(data) {
	return request({
		url: "/property/editData",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 资产移交
export function addPropertyLog(data) {
	return request({
		url: "/property/addPropertyLog",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}

// 资产移交数据列表
export function pageListPropertyLog(data) {
	return request({
		url: "/property/pageListPropertyLog",
		method: "post",
		headers: { "Content-Type": "multipart/form-data" },
		data,
	});
}
