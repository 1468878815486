<template>
  <div class="attendance-page view-page">
    <c-search searchTitle="员工姓名电话" placeholder="姓名" isDate @search="search" valueFormat="yyyyMMdd"></c-search>
    <c-navigation title="部门" :list="$store.state.departmentListAll" @change="changeDepartment"></c-navigation>
    <c-table :data="tableData">
      <!-- <el-table-column align="center" prop="date" label="选择">
        <template #default="{ row }">
          <el-checkbox></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="info.nickname" label="员工姓名"> </el-table-column>
      <el-table-column align="center" prop="info.mobile" label="手机号"> </el-table-column>
      <el-table-column align="center" prop="info.sex" label="性别">
        <template #default="{ row }">
          <p>{{ row.sex == 0 ? '男' : '女' }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="info.section" label="部门"> </el-table-column>
      <el-table-column align="center" prop="info.status" label="状态"> </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <div class="table-operate">
            <p class="operate-list edit" @click="edit(row.info)">查看</p>
          </div>
        </template>
      </el-table-column>
    </c-table>

    <!-- 考勤信息 -->
    <c-dialog v-model="isCheckingInInfo" title="" :cancel="false" :save="false">
      <div class="transferInfo">
        <div class="data">
          <c-search searchTitle="" :isSearch="false" @search="clientSearchDate" :isBorder="false" :isDate="true" valueFormat="yyyyMMdd"></c-search>
        </div>
        <div class="table-container">
          <c-table :data="personageList" :isPadding="false">
            <el-table-column align="center" prop="startime" label="早上卡"> </el-table-column>
            <el-table-column align="center" prop="endtime" label="晚上卡"> </el-table-column>
            <el-table-column align="center" prop="day" label="时间"> </el-table-column>
            <!-- 分页 -->
          </c-table>
        </div>
      </div>
      <div class="page">
        <c-pagination @change="changePagePersonage" :pageSize="personage.limit" :total="pCntactsTotal"></c-pagination>
      </div>
    </c-dialog>

    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="totalDataParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import CDialog from '@/components/c-dialog.vue'
import * as hrApi from '@/api/hr.js'
export default {
  name: 'Attendance',
  components: {
    CDialog
  },
  data() {
    return {
      tableData: [],
      totalDataParam: {
        username: '',
        starday: '',
        endday: '',
        section_id: '',
        page: 1,
        limit: 7
      },
      personageList: [], // 个人考勤数据列表
      personage: {
        admin_id: '',
        page: 1, //页数
        limit: 7, //每页展示总条数
        starday: '', // 	开始时间
        endday: '' // 结束时间
      },
      contactsTotal: 0,
      pCntactsTotal: 0,
      isDialog: false,
      datePickerValue: '',
      isCheckingInInfo: false // 考勤数据弹框
    }
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * @description: 初始化函数
     */
    async init() {
      const d = new Date()
      this.totalDataParam.endday = d.toLocaleDateString().split('/').join('-')
      const start = d.toLocaleDateString().split('/')
      start[2] = '01'
      this.totalDataParam.starday = start.join('-')
      this.getTotalData()
    },
    /**
     * @description: 获取列表数据
     */
    getTotalData() {
      hrApi.totalData(this.totalDataParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * @description: 搜索
     * @param {*} v: 搜索时的参数
     */
    search(v) {
      if (v.keyWord && v.date) {
        this.totalDataParam.username = v.keyWord
        this.totalDataParam.starday = v.date[0]
        this.totalDataParam.endday = v.date[1]
        this.getTotalData()
      } else if (v.keyWord && !v.date) {
        this.totalDataParam.username = v.keyWord
        this.totalDataParam.starday = ''
        this.totalDataParam.endday = ''
        this.getTotalData()
      } else if (!v.keyWord && v.date) {
        this.totalDataParam.username = ''
        this.totalDataParam.starday = v.date[0]
        this.totalDataParam.endday = v.date[1]
        this.getTotalData()
      } else {
        this.totalDataParam.username = ''
        this.totalDataParam.starday = ''
        this.totalDataParam.endday = ''
        this.getTotalData()
      }
    },
    /**
     * @description: 切换部门
     */
    changeDepartment(v) {
      this.totalDataParam.section_id = v.id
      this.getTotalData()
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.totalDataParam.page = i
      this.getTotalData()
    },
    /**
     * 个人考勤跳转页数
     */
    changePagePersonage(i) {
      this.personage.page = i
      this.getPersonageWorking()
    },
    /**
     * 查看个人考勤数据
     */
    edit(e) {
      this.personage.admin_id = e.id
      this.getPersonageWorking()
      this.isCheckingInInfo = true
    },
    /**
     * 考勤数据列表时间查询
     * @description: 客户查询
     * @param {*} e: 要查询的条件
     */
    clientSearchDate(e) {
      if (e.date) {
        this.personage.starday = e.date[0]
        this.personage.endday = e.date[1]
        this.getPersonageWorking()
      } else {
        this.personage.starday = ''
        this.personage.endday = ''
        this.getPersonageWorking()
      }
    },
    /**
     * 获取个人考勤数据
     */
    getPersonageWorking() {
      hrApi.personageWorking(this.personage).then(res => {
        this.personageList = res.data.data
        this.pCntactsTotal = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.attendance-page {
  background-color: #fff;
}
.list-item {
  padding: 0 1.6rem;
}
.operate {
  cursor: pointer;
}
.dialog {
  .data {
    display: flex;
    align-items: center;
    justify-content: center;

    .txt {
      font-size: 0.83rem;
      font-weight: 400;
      color: #333333;
    }
  }
  .table-container {
    margin: 2.8rem 0 0;
  }
}
</style>
